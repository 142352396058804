'use client'

import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { styled } from '@mui/joy'
import useIsScrollDown from '@page/article/_components/ArticleHeader/StickyHeader/hooks/useIsScrollDown'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import IconTabBarHome from 'src/assets/icons/IconTabBarHome'
import IconTabBarVideoNews from 'src/assets/icons/IconTabBarVideoNews'
import HistoryController from './controller/History'
import LiveController from './controller/Live'
import TTSController from './controller/TTS'
import MypageController from './controller/Mypage'
import { useRecoilValue } from 'recoil'
import { appInterface } from '@store/isApp'
import { useIsFocusedControl } from '@store/isFocused'
import { scrollToTopAndEnableAutoScrollRestoration } from '@utils/scroll'
import { useTTSControl } from '@store/tts'
import useRequestHaptic from '@hooks/Interface/useRequestHaptic'

export const BarLink = styled(UILink)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	flexGrow: '1',
	flexShrink: 0,
	width: '25%',
	'&> *:not(:first-child)': { marginTop: pxToRem(2) },
	height: pxToRem(63),
	paddingTop: pxToRem(7),
})
export const BarButton = styled('button')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	flexGrow: '1',
	flexShrink: 0,
	width: '25%',
	'&> *:not(:first-child)': { marginTop: pxToRem(2) },
	height: pxToRem(63),
	paddingTop: pxToRem(7),
	'&:disabled': {
		opacity: 0.3,
	},
})
export const ButtonText = styled(UIText)({
	fontSize: pxToRem(12),
	fontWeight: 500,
	lineHeight: pxToRem(12),
})

const TabBarRoot = () => {
	const { isFocused } = useIsFocusedControl()
	const { darkMode } = useDarkModeControl()
	const { onHaptic } = useRequestHaptic()
	const isScrollDown = useIsScrollDown()
	const pathname = usePathname()
	const nativeEvent = useRecoilValue(appInterface)

	const isArticle = pathname.includes('/article')
	const textColor = darkMode ? color.colWhite : color.colBlack
	const [hasPipMode, setHasPipMode] = useState(false)

	const { setIsTTSPlaying } = useTTSControl()

	const onClick = () => {
		onHaptic()
		scrollToTopAndEnableAutoScrollRestoration()
	}

	useEffect(() => {
		nativeEvent({
			key: 'requestMovePageUrl',
			value: {
				path: pathname,
				pageUrl: global.location?.href ?? '',
			},
		})
	}, [nativeEvent, pathname])

	useEffect(() => {
		const handleScroll = () => {
			const player = document.getElementById('jtbc-video-player')

			if (player) {
				setHasPipMode(player.classList.contains('pip-mode'))

				// 구형 폰에서 같은 pathname 이동시 plater 화면이 깨지는 현상 처리
				if (window.scrollY === 0) {
					player.style.removeProperty('bottom')
				}
			}
		}

		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		const player = document.getElementById('jtbc-video-player')

		if (player) {
			if (!hasPipMode || !isArticle || ![...player.classList].includes('pip-mode')) {
				player.style.removeProperty('bottom')
			} else {
				player.style.setProperty('bottom', isScrollDown ? '110px' : '164px', 'important')
			}
		}
	}, [hasPipMode, isArticle, isScrollDown])

	useEffect(() => {
		return () => {
			setIsTTSPlaying(false)
		}
	}, [setIsTTSPlaying])

	return (
		!isFocused && (
			<UIFlex
				bgcolor={darkMode ? color.colBlack : color.colWhite}
				borderTop={`1px solid ${darkMode ? '#303030' : color.colGray3}`}
				flexDirection="row"
				position="fixed"
				bottom={0}
				left={0}
				right={0}
				zIndex={zIndexes.header - 3}
				mt="auto"
				sx={{
					display: isArticle && isScrollDown ? 'none' : 'flex',
				}}
			>
				{isArticle ? (
					<>
						<BarLink href="/" onClick={onClick}>
							<IconTabBarHome color={textColor} />
							<ButtonText color={textColor}>홈</ButtonText>
						</BarLink>
						<HistoryController textColor={textColor} />
						<TTSController textColor={textColor} />
					</>
				) : (
					<>
						<BarLink href="/" onClick={onClick}>
							<IconTabBarHome color={textColor} />
							<ButtonText color={textColor}>홈</ButtonText>
						</BarLink>
						<LiveController textColor={textColor} onClick={onClick} />
						<BarLink href="/program" onClick={onClick}>
							<IconTabBarVideoNews color={textColor} />
							<ButtonText color={textColor}>다시보기</ButtonText>
						</BarLink>
						<MypageController textColor={textColor} onClick={onClick} />
					</>
				)}
			</UIFlex>
		)
	)
}

export default TabBarRoot
