import { useRecoilValue } from 'recoil'
import { appInterface } from '@store/isApp'

/** 앱에서 햅틱 효과 발생 */
const useRequestHaptic = () => {
	const nativeEvent = useRecoilValue(appInterface)
	const onHaptic = () => nativeEvent({ key: 'requestHaptic', value: null })

	return {
		onHaptic,
	}
}
export default useRequestHaptic
